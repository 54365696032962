<template>
  <main class="main">
    <div class="container">
      <div class="main__inner main__inner">
        <section v-if="ddService">
          <div class="title__wrap">
            <h3>Единая дежурная диспетчерская служба</h3>
          </div>
          <BreadcrumbsComponent title="Единая дежурная диспетчерская служба " />
          <section class="section__feedback-dd-service section__divider">
            <div class="dd-service__text">
              <EditorJSComponent
                :text="JSON.parse(ddService.description)"
                v-if="JSON.parse(ddService.description)"
              />
            </div>
            <button @click="sendOfficialAppeal" class="dd-service__btn btn-blue btn--small">
              Направить официальное обращение
            </button>
          </section>
<!--          <section class="section">-->
<!--            <h3>Статистика обращений в Единую дежурную диспетчерскую службу</h3>-->
<!--            <ChartsList />-->
<!--          </section>-->
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import ChartsList from "@/views/feedback/components/ChartsList.vue";

export default {
  name: "DutyDispatchServicePage",
  async asyncData({ store }) {
    await store.dispatch("GET_DD_SERVICE_PAGE");
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ddService() {
      if (this.$store.state.dd_service_page && this.$store.state.dd_service_page.duty_dispatch_services) {
        return this.$store.state.dd_service_page.duty_dispatch_services;
      }
      return {};
    },
    dispatchDepartment() {
      return this.$store.state.dd_service_page.dispatch_department;
    },
  },
  methods: {
    sendOfficialAppeal() {
      if (this.dispatchDepartment) {
        const ddStr = JSON.stringify(this.dispatchDepartment);
        localStorage.setItem("dispatchDepartment", ddStr);
      }
      this.$router.push({ name: "official_appeal" });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.dd_service_page = {};
    next();
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Organization",
    };
  },
  components: {
    BreadcrumbsComponent,
    EditorJSComponent,
    ChartsList,
  },
};
</script>

<style lang="stylus">

.section__feedback-dd-service {
  padding 32px

  .dd-service__text {
    margin-bottom: 24px;
  }
}
</style>
